import { create } from 'zustand'


interface IInvite {
  id: string
  is_free: boolean
  expiry_datetime: string
  validity: boolean
  attributed: boolean
}

interface InviteStore {
  invite: IInvite
  setInvite: (invite: IInvite) => void
}


const useInviteStore = create<InviteStore>((set) => ({
  invite: {
    validity: false,
    id: "",
    is_free: false,
    expiry_datetime: "",
    attributed: false
  },
  setInvite: (invite: IInvite) => set({ invite }),
}))

export default useInviteStore