import { create } from "zustand";

interface ErrorStore {
  isAPIError: boolean;
  title: string;
  description: string;
  setError: (title: string, message: string) => void;
  setIsAPIError: (isAPIError: boolean) => void;
}

export const useErrorStore = create<ErrorStore>((set) => ({
  isAPIError: false,
  title: "",
  description: "",
  setError: (title, description) => set({ title, description }),
  setIsAPIError: (isAPIError) => set({ isAPIError }),
}));
