import bgImage from "@assets/bg_form.png";
import Roller from "@assets/roller.png";
import { Box, Image, VStack, Text, Button } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { OctagonAlert, Undo2 } from "lucide-react";
import React from "react";

import { useErrorStore } from "@/stores/error";
interface ErrorProps {
  error?: AxiosError;
  resetErrorBoundary?: () => void;
}

const Error: React.FC<ErrorProps> = () => {
  const boxes = Array(3).fill(null);
  const [boxHeight, setBoxHeight] = React.useState(0);
  const [boxWidth, setBoxWidth] = React.useState(0);
  const boxRef = React.useRef<HTMLDivElement>(null);
  const { title, description } = useErrorStore((state) => state);
  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === boxRef.current) {
          setBoxHeight(entry.contentRect.height);
          setBoxWidth(entry.contentRect.width);
        }
      }
    });

    if (boxRef.current) {
      setBoxHeight(boxRef.current.clientHeight);
      setBoxWidth(boxRef.current.clientWidth);
      resizeObserver.observe(boxRef.current);
    }
    const node = boxRef.current;
    return () => {
      if (node) {
        resizeObserver.unobserve(node);
      }
    };
  }, []);

  return (
    <Box
      w="100%"
      h="100%"
      backgroundColor="black"
      bgImage={bgImage}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      overflowY="auto"
      sx={{
        scrollbarWidth: "none",
      }}
    >
      <Box
        w="100%"
        h="100%"
        m={0}
        backdropFilter="auto"
        backgroundColor="rgba(16, 16, 16, 0.1)"
        backdropBlur="10px"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 1)"
        position="relative"
        zIndex={2}
      >
        <Image
          src={Roller}
          alt="Roller"
          w="10rem"
          h="10rem"
          position="absolute"
          top="30%"
          left="50%"
          transform="translateX(-50%)"
          zIndex={1}
        />
        {boxes.map((_, index) => (
          <Box
            key={index}
            w={boxWidth ? `${boxWidth}px` : "auto"}
            h={boxHeight ? `${boxHeight}px` : "auto"}
            top="40%"
            left="50%"
            position="absolute"
            transform="translateX(-50%)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex={2}
            backdropFilter="auto"
            backdropBlur="8px"
            borderRadius="1rem"
          />
        ))}
        <Box
          ref={boxRef}
          w="80%"
          h="auto"
          top="40%"
          left="50%"
          position="absolute"
          transform="translateX(-50%)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          background="rgba(255, 255, 255, 0.02)"
          zIndex={2}
          backdropFilter="auto"
          backdropBlur="18px"
          borderRadius="1rem"
        >
          <VStack m="1rem" pt="0.5rem" justifyContent="center" spacing="1rem">
            <OctagonAlert size="20" color="#FFFFFF" />
            <Text color="white" fontSize="xl" fontWeight="700">
              {title}
            </Text>
            {description && (
              <Box
                w="100%"
                h="auto"
                p="1rem"
                background="rgba(255, 255, 255, 0.02)"
                borderRadius="0.5rem"
              >
                <Text
                  fontSize="sm"
                  fontWeight="400"
                  color="rgba(255, 255, 255, 0.48)"
                >
                  {description}
                </Text>
              </Box>
            )}
            <Box display="flex" justifyContent="center" w="100%">
              <Button
                colorScheme="#ffffff"
                width="full"
                height="3.5rem"
                bgGradient="silver"
                border="1px solid rgba(128, 128, 128, 0.5)"
                background="rgba(16, 16, 16, 0.9)"
                borderRadius="0.5rem"
                rightIcon={<Undo2 size={16} color="white" />}
                onClick={() => (window.location.href = "https://8club.co")}
              >
                Go to home
              </Button>
            </Box>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};

export default Error;
