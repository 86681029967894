import { Center, Spinner } from "@chakra-ui/react";
import * as platform from "platform";
import React, { useEffect } from "react";

const DownloadRedirect: React.FC = () => {
  useEffect(() => {
    const currentPlatformOS =  platform.os?.family;
    const manufacturer= platform.manufacturer;
    if (currentPlatformOS === "iOS" || currentPlatformOS === "macOS" || currentPlatformOS=== "OS X" || manufacturer === 'Apple') {
    window.location.href = "https://apps.apple.com/us/app/8club-get-invited-to-hotspots/id6547866574";
    } else {
    window.location.href = "https://play.google.com/store/apps/details?id=co.eightclub.app";
    }
  }, []);

  return (
    <Center height="100vh">
      <Spinner size="xl" color="blue.500" />
    </Center>
  );
};

export default DownloadRedirect;
