import { Button, Image, Input, Box } from "@chakra-ui/react";
import { CircleCheckIcon, ImagePlusIcon, SquarePenIcon } from "lucide-react";
import { useRef } from "react";

import useUserStore from "@/stores/user";

const FormProfilePictureUpload = () => {
  const user = useUserStore((state) => state.user);

  if (user?.profile_image_url) {
    return (
      <Button
        backgroundColor="surfaceWhite1"
        color="text4"
        width="full"
        height="3.5rem"
        borderRadius="0.5rem"
        leftIcon={
          <Image
            src={user.profile_image_url}
            boxSize="40px"
            borderRadius="4px"
          />
        }
        justifyContent="start"
        border="1px solid rgba(255, 255, 255, 0.08)"
        _active={{ backgroundColor: "surfaceWhite1" }}
        _focus={{ backgroundColor: "surfaceWhite1" }}
      >
        Profile photo uploaded
        <CircleCheckIcon
          size={16}
          color="rgba(255, 255, 255, 0.32)"
          style={{ marginLeft: "6px" }}
        />
      </Button>
    );
  }

  return <FormProfilePictureUploadButton />;
};

const FormProfilePictureUploadButton = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const setPhoto = useUserStore((state) => state.setPhoto);
  const photo = useUserStore((state) => state.photo);

  const handlePhotoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhoto(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const renderButton = () => {
    if (photo == null) {
      return (
        <Button
          backgroundColor="surfacePurple1"
          color="purple"
          width="full"
          height="3.5rem"
          borderRadius="0.5rem"
          leftIcon={<ImagePlusIcon size={16} color="#9196FF" />}
          justifyContent="start"
          border="1px dashed rgba(145, 150, 255, 1)"
          onClick={handleUploadClick}
          _active={{ backgroundColor: "surfacePurple1" }}
          _focus={{ backgroundColor: "surfacePurple1" }}
        >
          Upload profile photo
        </Button>
      );
    }

    return (
      <Button
        backgroundColor="surfaceWhite1"
        color="text4"
        width="full"
        height="3.5rem"
        borderRadius="0.5rem"
        leftIcon={
          <Image src={photo as string} boxSize="40px" borderRadius="4px" />
        }
        justifyContent="start"
        border="1px solid rgba(255, 255, 255, 0.08)"
        onClick={handleUploadClick}
        _active={{ backgroundColor: "surfaceWhite1" }}
        _focus={{ backgroundColor: "surfaceWhite1" }}
      >
        Profile photo selected
        <CircleCheckIcon
          size={16}
          color="rgba(255, 255, 255, 0.32)"
          style={{ marginLeft: "6px" }}
        />
        <Box ml="auto">
          <SquarePenIcon size={16} color="#9196FF" />
        </Box>
      </Button>
    );
  };

  return (
    <>
      {renderButton()}
      <Input
        ref={inputRef}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handlePhotoUpload}
      />
    </>
  );
};

export default FormProfilePictureUpload;
