import Spin from "@components/Spinner";
import useFetchHotspotInvite from "@hooks/useFetchHotspotInvite";
import useFetchUser from "@hooks/useFetchUser";
import { useErrorStore } from "@stores/error";
import useHotspotStore from "@stores/hotspot";
import Error from "@views/Error";
import HotspotInfo from "@views/HotspotInfo";
import HotspotLanding from "@views/HotspotLanding";
import React, { useEffect, useState} from "react";
import { Navigate, useSearchParams } from "react-router-dom";

const Invite: React.FC = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code") as string;
  const { isLoading, isError } = useFetchHotspotInvite(code);
  const [stage, setStage] = useState(0);
  const validity = useHotspotStore((state) => state.validity);
  const { isError: userError } = useFetchUser();
  const showInterestedButton = !validity || isError;
  const { setError, isAPIError, setIsAPIError } = useErrorStore();


  useEffect(() => {
    if (userError) {
      setError("Error", "Failed to fetch user");
      setIsAPIError(true);
    }
  }, [userError, setError, setIsAPIError]);

  useEffect(() => {
    if (isError) {
      setError("Error", "Failed to fetch hotspot");
      setIsAPIError(true);
    }
  }, [isError, setError, setIsAPIError]);

  if (!code) {
    // Redirect to /download if no code is provided
    return <Navigate to={`/download`} replace />;
  }
  if (isLoading) {
    return <Spin />;
  } else if (isAPIError) {
    return <Error />;
  }

  switch (stage) {
    case 0:
      return (
      <HotspotLanding
        setStage={setStage}
        showInterestedButton={showInterestedButton}
        code={code}
      />
      );
    case 1:
      return (
        <HotspotInfo
          setStage={setStage}
          showInterestedButton={showInterestedButton}
          showWaitlistButton={false}
        />
      );
  }  
};

export default Invite;
