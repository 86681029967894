import axios from "axios";

import baseURL from "@/config";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  baseURL,
  withCredentials: false,
});

axiosInstance.interceptors.request.use((config) => {
  const jwtToken = localStorage.getItem("token");
  if (jwtToken) {
    config.headers.Authorization = `Bearer ${jwtToken}`;
  }
  return config;
});

// 401 interceptor to handle unauthorized access:
// If the server returns a 401 error, then just delete the token, and
// we do not need to redirect to the login page, because there might be 
// some pages where we do not want to redirect to the login page.
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Optionally, clear any stored auth tokens
      localStorage.removeItem('token');
      // Reload the current page
      window.location.reload();
    }
    return Promise.reject(error);
  }
);


export default axiosInstance;
