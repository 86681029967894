import { VStack, Container, Fade, Flex, Input, Text } from "@chakra-ui/react";
import React from "react";

import PrimaryCTA from "@/buttons/PrimaryCTA";

interface AuthPhoneNumberProps {
  phoneNumber: string;
  onChanged: (phoneNumber: string) => void;
  isNumberValid: boolean;
  onTapVerifyOTP: () => void;
}

const AuthPhoneNumber: React.FC<AuthPhoneNumberProps> = ({
  phoneNumber,
  onChanged,
  isNumberValid,
  onTapVerifyOTP,
}) => {
  const [isFocused, setIsFocused] = React.useState(false);
  return (
    <Container
      backgroundColor="b2"
      borderRadius="0.75rem"
      backdropFilter="auto"
      backdropBlur="16px"
      position={isFocused ? "relative" : "fixed"}
      bottom={isFocused ? "0" : "2rem"}
      transition="all 0.5s ease-in-out"
      width="calc(100% - 2rem)"
    >
      <VStack
        borderRadius="lg"
        boxShadow="md"
        mt="2rem"
        spacing={2}
        align="stretch"
        py={4}
        maxW="100%"
      >
        <VStack textAlign="center" mb={6} spacing={1}>
          <Text
            mb={4}
            color="white"
            fontWeight="400"
            fontSize="md"
            whiteSpace="nowrap "
          >
            Enter phone number to continue
          </Text>
          <Flex align="center" pt="1rem">
            <Text color="#FFFFFFB8" fontWeight="400" fontSize="2xl">
              +91 -
            </Text>
            <Input
              id="phone-number"
              type="number"
              placeholder="XXXXX XXXXX"
              value={phoneNumber}
              onChange={(e) => onChanged(e.target.value)}
              onFocus={() => setIsFocused(true)}
              border="none"
              p={2}
              _focus={{ boxShadow: "none" }}
              _placeholder={{ color: "#FFFFFF29", fontSize: "1.3rem" }}
              color="white"
              fontSize="2xl"
              maxW="calc(1ch * 10.7)"
            />
          </Flex>
        </VStack>

        <Fade in={isNumberValid}>
          <PrimaryCTA
            text="Verify OTP"
            onClick={onTapVerifyOTP}
            display={isNumberValid}
          />
        </Fade>
      </VStack>
    </Container>
  );
};

export default AuthPhoneNumber;
