import { Box } from "@chakra-ui/react";

interface PointProps {
  isWhite?: boolean;
  isRed?: boolean;
}

const Point: React.FC<PointProps> = ({ isWhite, isRed }) => {
  let bgColor = "rgba(99, 255, 96, 1)";
  let boxShadowColor = "rgba(72, 187, 120, 0.7)";

  if (isRed) {
    bgColor = "red";
    boxShadowColor = "rgba(255, 0, 0, 0.7)";
  } else if (isWhite) {
    bgColor = "white";
    boxShadowColor = "rgba(200, 200, 200, 0.7)";
  }

  return (
    <Box
      w="0.5rem"
      h="0.5rem"
      bg={bgColor}
      borderRadius="full"
      boxShadow={`0 0 4px 4px ${boxShadowColor}`}
    />
  );
};

export default Point;
