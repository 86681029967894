import { Box, Button } from "@chakra-ui/react";
import { DiamondPlusIcon } from "lucide-react";
import React from "react";

interface JoinWaitlistButtonProps {
  onClick: () => void;
}

const JoinWaitlistButton: React.FC<JoinWaitlistButtonProps> = ({ onClick }) => {
  return (
    <Box display="flex" justifyContent="center" mx="1rem">
      <Button
        colorScheme="#ffffff"
        width="full"
        height="3.5rem"
        bgGradient="silver"
        border="1px solid rgba(128, 128, 128, 0.5)"
        background="rgba(16, 16, 16, 0.9)"
        borderRadius="0.5rem"
        leftIcon={<DiamondPlusIcon size={16} color="white" />}
        onClick={onClick}
      >
        Join the Waitlist!
      </Button>
    </Box>
  );
};

export default JoinWaitlistButton;
