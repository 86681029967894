"use client";
import { ChakraProvider } from "@chakra-ui/react";
import DownloadRedirect from "@pages/DownloadRedirect"; 
import Invite from "@pages/Invite";
import Share from "@pages/Share";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Error from "@views/Error";
import { AxiosError } from "axios";
import React from "react";
import "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes, Navigate, useLocation, useParams } from "react-router-dom";

import theme from "./theme";

declare module "@tanstack/react-query" {
  interface Register {
    defaultError: AxiosError;
  }
}

const queryClient = new QueryClient();

const ExternalRedirect: React.FC = () => {
  window.location.href = "https://www.8club.co";
  return null;
};
// Helper components to redirect and retain query params
const NavigateToInvite: React.FC = () => {
  const location = useLocation();
  return <Navigate to={`/app/invite${location.search}`} replace />;
}

const NavigateToShare: React.FC = () => {
  const { code } = useParams() as { code: string };
  if (code) {
    return <Navigate to={`/share?code=${code}`} replace />;
  }
  return <Error />;
};

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <div className="app-container">
          <ErrorBoundary fallback={<Error />}>
            <Routes>
              <Route
                path="/invite"
                element={<NavigateToInvite />}
              />
              <Route path="/app/invite/" element={<Invite />} />
              <Route path="/share/:code" element={<NavigateToShare />} />
              <Route path="/share" element={<Share />} />
              <Route path="/download" element={<DownloadRedirect />} />
              <Route path="/error" element={<Error />} />
              <Route path="/*" element={<ExternalRedirect />} />
            </Routes>
          </ErrorBoundary>
        </div>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
