import { Box, Spinner } from "@chakra-ui/react";
import React from "react";

interface SpinnerProps {
  isBlack?: boolean;
}

const Spin: React.FC<SpinnerProps> = ({ isBlack = true }) => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      background={isBlack ? "black" : "transparent"}
    >
      <Spinner size="xl" maxWidth="100%" maxHeight="100%" color="gray" />
    </Box>
  );
};

export default Spin;
