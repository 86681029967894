import bgImage from "@assets/stay_tuned.gif";
import { Box, Text, Image, VStack, HStack, Container } from "@chakra-ui/react";
import { UserGender } from "@interfaces/user";
import useUserStore from "@stores/user";
import React from "react";

import Point from "@/components/Point";

const WaitlistCard: React.FC = () => {
  const user = useUserStore((state) => state.user);
  const age =
    new Date().getFullYear() -
    parseInt(user?.date_of_birth?.split("-")[0] ?? "");

  return (
    <Box
      w="100%"
      bg="linear-gradient(334.43deg, rgba(255, 255, 255, 0.1) 2.76%, rgba(255, 255, 255, 0.01) 41.65%, rgba(255, 255, 255, 0.1) 100%)"
      borderRadius="1rem"
      p="4"
      color="white"
      boxShadow="lg"
      backdropFilter="auto"
      backdropBlur="16px"
    >
      <HStack justifyContent="space-between" mb="1rem">
        <HStack spacing={3}>
          <Image
            src={user?.profile_image_url}
            boxSize="2.5rem"
            borderRadius="0.25rem"
          />
          <VStack alignItems="flex-start" spacing={0}>
            <Text fontWeight="bold" fontSize="sm">
              {user?.name}
            </Text>
            <Text color="formHeading" fontSize="xs">
              {age} yrs. old | {user?.gender ? UserGender[user?.gender] : ""}
            </Text>
          </VStack>
        </HStack>
      </HStack>
      <Box
        borderRadius="0.5rem"
        textAlign="center"
        backgroundImage={bgImage}
        backgroundSize="cover"
        backgroundPosition="center -110px"
      >
        <VStack
          pt="9rem"
          pb={1}
          width="100%"
          bgGradient="linear(to-b, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))"
        >
          <Text fontSize="2xl" fontWeight="800">
            The host has been <br /> notified!
          </Text>
          <Container
            borderRadius="0.25rem"
            py="0.5rem"
            background="#10101080"
            backdropFilter="auto"
            backdropBlur="8px"
          >
            <HStack spacing={2} align="center" justify="center">
              <Point />
              <Text fontWeight="400" color="white" fontSize="xs">
                You'll be notified by text if you're invited.
              </Text>
            </HStack>
          </Container>
        </VStack>
      </Box>
    </Box>
  );
};

export default WaitlistCard;
