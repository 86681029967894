import { useGetHotspotFromCode } from "@api/hotspot";
import { useGetInviteFromCode } from "@api/invite";
import useHotspotStore from "@stores/hotspot";
import useInviteStore from "@stores/invite";
import { useEffect } from "react";

import displayToast from "@/utils/toasts";

const useFetchHotspotInvite = (code: string) => {
  const { data, isLoading: isHotspotLoading, isError: isHotspotError } = useGetHotspotFromCode(code);
  const { data: inviteData, isLoading: isInviteLoading, isError: isInviteError, error } = useGetInviteFromCode(code);
  const setHotspot = useHotspotStore((state) => state.setHotspot);
  const setValidity = useHotspotStore((state) => state.setValidity);
  const setInvite = useInviteStore((state) => state.setInvite);
  const toastMessage = "Invite code has expired";
  const isLoading = isHotspotLoading || isInviteLoading;
  const isError = isHotspotError || isInviteError;
  useEffect(() => {
    if (data) {
      setValidity(data.validity);
      setHotspot(data.hotspot);
    }
  }, [data, setHotspot, setValidity]);

  useEffect(() => {
    if (isInviteError && error?.response?.status !== 403) {
      displayToast("Something went wrong. Retry again after sometime", { type: "error" });
    } else if (isInviteError && error?.response?.status === 403) {
      setInvite({ validity: false, id: "", is_free: false, expiry_datetime: "", attributed: false });
    }
  }, [error, isInviteError, inviteData, toastMessage, setInvite]);


  useEffect(() => {
    if (inviteData) {
      setInvite(inviteData);
    }
  }, [inviteData, setInvite]);

  return { isLoading, isError };
};

export default useFetchHotspotInvite;
