import { Container, Text, HStack } from "@chakra-ui/react";
import useHotspotStore from "@stores/hotspot";
import React from "react";

import Point from "@/components/Point";

interface HotspotDetailsProps {
  isWhite?: boolean;
  isWaitlistPage?: boolean;
}

const HotspotDetails: React.FC<HotspotDetailsProps> = ({
  isWhite = false,
  isWaitlistPage = false,
}) => {
  const hotspot = useHotspotStore((state) => state.hotspot);
  return (
    <Container
      borderRadius="0.5rem"
      py="1rem"
      backgroundColor={isWaitlistPage ? "#FFFFFF0F" : "b2"}
      backdropFilter="auto"
      backdropBlur="8px"
      width="auto"
      minW="80%"
      maxW="100%"
    >
      <HStack spacing={2} alignItems="center" mx="auto" width="fit-content">
        <Point isWhite={isWhite} />
        <Text fontWeight="700" color="white" noOfLines={1} maxW="60%">
          {hotspot?.name ?? ""}
        </Text>
        <Text fontWeight="400" color="#FFFFFF7A" noOfLines={1}>
          by {hotspot?.host?.user?.name ?? ""}
        </Text>
      </HStack>
    </Container>
  );
};

export default HotspotDetails;
