import { useGetUser } from "@api/user";
import useUserStore from "@stores/user";
import { useEffect } from "react";

const useFetchUser = () => {
  const { data, isError, isLoading, isSuccess, refetch, error } = useGetUser();
  const { setUser } = useUserStore();

  useEffect(() => {
    if (isSuccess && data) {
      const experienceIds =
        data.interested_experiences?.map((exp: any) => exp.experience.id) || [];
      setUser({ ...data, interested_experiences: experienceIds });
    }
  }, [data, isSuccess, setUser]);

  return { isLoading, isSuccess, refetch, isError, error };
};

export default useFetchUser;  
