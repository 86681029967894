import logo from "@assets/logo.png";
import { Box, HStack, Image } from "@chakra-ui/react";
import { Headset } from "lucide-react";
import React from "react";

import { whatsappLink } from "@/config";

const Header: React.FC = () => {
  return (
    <HStack justifyContent="space-between" p="2rem">
      <Image src={logo} alt="Logo" />
      <Box
        borderRadius="0.5rem"
        background="#FFFFFF10"
        backdropFilter="auto"
        backdropBlur="8px"
        border="1px solid #FFFFFF99"
        p={2}
      >
        <Headset
          size={20}
          color="white"
          onClick={() => window.open(whatsappLink, "_blank")}
        />
      </Box>
    </HStack>
  );
};

export default Header;
