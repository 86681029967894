import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

import axiosInstance from "./axiosInstance";

const getHotspotFromCode = async (code: string) => {
  try {
    const res = await axiosInstance.get(`/v1/hotspots/code/${code}`);
    return res.data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    return e;
  }
};

const attributeHotspotCode = async (code: string) => {
  try {
    const res = await axiosInstance.post(
      `/v1/hotspots/attribute-hotspot-code/${code}`,
    );
    return res.data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
  }
};

const getGuestList = async (id: number) => {
  try {
    const params = {
      size: 3,
      page: 1,
      status: 2,
    }
    const res = await axiosInstance.get(`/v1/hotspots/${id}/guest-list`, { params });
    return res.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    return e;
  }
}

export const useGetHotspotFromCode = (code: string) => {
  const query = useQuery({
    queryKey: ["hotspot", code],
    queryFn: () => getHotspotFromCode(code),
  });
  return query;
};

export const useAttributeHotspotCode = (code: string) => {
  const mutation = useMutation({
    mutationKey: ["attributeHotspotCode", code],
    mutationFn: () => attributeHotspotCode(code),
  });
  return mutation;
};

export const useGetGuestList = (id: number) => {
  const query = useQuery({
    queryKey: ["guestList", id],
    queryFn: () => getGuestList(id),
  });
  return query;
}
