import { IUpdateUser } from "@interfaces/user";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";

import axiosInstance from "./axiosInstance";

const getUser = async () => {
  try {
    const res = await axiosInstance.get("/v1/users/me");
    return res.data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    return e;
  }
};

const updateUser = async (user: IUpdateUser) => {
  try {
    const res = await axiosInstance.patch("/v1/users", user);
    return res.data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    return e;
  }
};

const submitApplication = async () => {
  try {
    const res = await axiosInstance.post("/v1/users/application/submit");
    return res.data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    return e;
  }
};

export const useGetUser = () => {
  const jwtToken = localStorage.getItem("token");

  const query = useQuery({
    queryKey: ["user"],
    queryFn: getUser,
    enabled: !!jwtToken,
  });
  return query;
};

export const useUpdateUser = () => {
  const mutation = useMutation({
    mutationKey: ["updateUser"],
    mutationFn: (user: IUpdateUser) => updateUser(user),
  });
  return mutation;
};

export const useSubmitApplication = () => {
  const mutation = useMutation({
    mutationKey: ["submitApplication"],
    mutationFn: submitApplication,
  });
  return mutation;
};
