import { toast, ToastOptions } from "react-toastify";

const displayToast = (message: string, options: ToastOptions = {}) => {
  const defaultOptions: ToastOptions = {
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    theme: "dark",
    type: "error",
    toastId: "customToast",
  };

  const mergedOptions = { ...defaultOptions, ...options };

  toast(message, mergedOptions);
};

export default displayToast;
