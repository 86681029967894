import { Button } from "@chakra-ui/react";
import { ArrowBigRightDashIcon } from "lucide-react";
import React from "react";

interface PrimaryCTAProps {
  text: string;
  display: boolean;
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const PrimaryCTA: React.FC<PrimaryCTAProps> = ({
  text,
  display,
  onClick,
  isDisabled = false,
  isLoading = false,
}) => {
  return (
    <Button
      colorScheme="#ffffff"
      width="full"
      height={display ? "3.5rem" : "0rem"}
      transition="height 0.3s"
      bgGradient="silver"
      border="1px solid rgba(128, 128, 128, 0.5)"
      borderRadius="0.5rem"
      rightIcon={<ArrowBigRightDashIcon size={24} color="white" />}
      onClick={onClick}
      isDisabled={isDisabled}
      isLoading={isLoading}
    >
      {text}
    </Button>
  );
};

export default PrimaryCTA;
