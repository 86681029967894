import { Box, Button } from "@chakra-ui/react";
import React from "react";
import { LiaDownloadSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";

interface DownloadAppButtonProps {
  isFullWidth?: boolean;
}
const DownloadAppButton: React.FC<DownloadAppButtonProps> = ({
  isFullWidth = true,
}) => {
  const navigate = useNavigate();
  // const isiOS = () =>
  //   /iPad|iPhone|iPod/.test(navigator.userAgent) ||
  //   (navigator.userAgent.includes("Mac") && "ontouchend" in document);

  const onClick = () => {
    navigate('/download');
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      w={isFullWidth ? "100%" : "auto"}
      mx="1rem"
    >
      <Button
        colorScheme="#ffffff"
        width="full"
        height="3.5rem"
        bgGradient="silver"
        border="1px solid rgba(128, 128, 128, 0.5)"
        background="rgba(16, 16, 16, 0.9)"
        borderRadius="0.5rem"
        leftIcon={<LiaDownloadSolid size={16} color="white" />}
        onClick={onClick}
      >
        Download The App
      </Button>
    </Box>
  );
};

export default DownloadAppButton;
