import { useSubmitOTP } from "@api/otp";
import { VStack, Container, Text, Link } from "@chakra-ui/react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";

import { useErrorStore } from "@/stores/error";
import displayToast from "@/utils/toasts";

dayjs.extend(duration);

const formatTime = (seconds: number) => {
  const timeDuration = dayjs.duration(seconds, "seconds");
  const minutes = timeDuration.minutes();
  const secs = timeDuration.seconds();
  return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
};

interface VerifyOTPProps {
  number: string;
  resendOTP: () => void;
  successOTP: (token: string) => void;
}

const VerifyOTP: React.FC<VerifyOTPProps> = ({
  number,
  resendOTP,
  successOTP,
}) => {
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const { mutate, isError, isSuccess, data, error } = useSubmitOTP();
  const { setIsAPIError, setError } = useErrorStore();
  const handleChange = (otp: string) => {
    setOtp(otp);
    if (otp.length === 4) {
      mutate({ phone: number, otp });
    }
  };

  const onClickesend = () => {
    setTimeLeft(60);
    setCanResend(false);
    resendOTP();
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    } else {
      setCanResend(true);
    }
  }, [timeLeft]);

  useEffect(() => {
    if (isSuccess) {
      successOTP(data?.token);
    }
    if (isError) {
      if (error?.response?.status === 403) {
        displayToast("Invalid OTP. Please try again.", { type: "warning" });
      } else {
        setError("Error verifying OTP", "Please try again after some time.");
        setIsAPIError(true);
      }
    }
  }, [isSuccess, isError, data, error, successOTP, setError, setIsAPIError]);

  return (
    <Container
      backgroundColor="b2"
      borderRadius="0.75rem"
      backdropFilter="auto"
      backdropBlur="16px"
    >
      <VStack
        borderRadius="lg"
        boxShadow="md"
        mt="1rem"
        spacing={4}
        align="center"
        py={4}
      >
        <VStack textAlign="center" mb={6} spacing={6}>
          <Text mb={4} color="white" fontWeight="400" fontSize="md">
            Enter OTP sent to +91-{number}
          </Text>
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={4}
            renderSeparator={<span> </span>}
            renderInput={(props) => (
              <input
                {...props}
                type="number"
                placeholder="X"
                className="otp-input"
                style={{
                  width: "2rem",
                  height: "2rem",
                  margin: "0 0.5rem",
                  fontSize: "2xl",
                  borderRadius: 4,
                  color: "white",
                  backgroundColor: "transparent",
                  textAlign: "center",
                }}
              />
            )}
          />
        </VStack>
        <Text color="white" mt={4}>
          {canResend ? (
            <Link onClick={onClickesend} fontSize="md" fontWeight="bold">
              Resend OTP
            </Link>
          ) : (
            <>
              <Text as="span" color="white.400" fontSize="md" fontWeight="bold">
                {formatTime(timeLeft)}
              </Text>
              <Text as="span" color="#FFFFFF52" fontSize="md" ml={2}>
                Resend OTP
              </Text>
            </>
          )}
        </Text>
      </VStack>
    </Container>
  );
};

export default VerifyOTP;
