import { useAcceptInvite } from "@api/invite";
import verified from "@assets/verified.png";
import { Container, HStack, Image, Text, Flex, VStack } from "@chakra-ui/react";
import { WaitlistStatus } from "@interfaces/user";
import useHotspotStore from "@stores/hotspot";
import useInviteStore from "@stores/invite";
import useUserStore from "@stores/user";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import HotspotCardHeader from "./HotspotCardHeader";

import AcceptInviteButton from "@/buttons/AcceptInviteButton";
import InterestedButton from "@/buttons/InterestedButton";
import displayToast from "@/utils/toasts";

interface HotspotCardProps {
  setStage?: (stage: number) => void;
  showInterestedButton?: boolean;
  code: string;
}

const HotspotCard: React.FC<HotspotCardProps> = ({
  setStage,
  showInterestedButton = false,
  code,
}) => {
  const navigate = useNavigate();
  const handleAcceptInvite = () => {
    navigate("/download");
  };
  const hotspot = useHotspotStore((state) => state.hotspot);
  const user = useUserStore((state) => state.user);
  const invite = useInviteStore((state) => state.invite);
  if (invite.attributed) {
    setStage?.(4);
  }
  const stackRef = useRef<HTMLDivElement>(null);
  const [stackHeight, setStackHeight] = useState(0);
  const numberOfBlurDivs = 20;
  const heightDelta = stackHeight / numberOfBlurDivs;
  const blurDivs = Array(numberOfBlurDivs).fill(null);
  const { isSuccess, isError, mutate, isPending } = useAcceptInvite();

  const buttonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!user) {
      setStage?.(2);
    } else if (
      user.status === WaitlistStatus.DRAFT ||
      user.status === WaitlistStatus.REJECTED
    ) {
      setStage?.(3);
    } else if (
      (code.includes("invite") && invite.validity) ||
      code.includes("share")
    ) {
      setStage?.(4);
    } else {
      setStage?.(5);
    }
  };

  useEffect(() => {
    if (code.includes("invite") && invite.validity && invite.is_free) {
      mutate(invite.id);
    }
  }, [code, invite.id, mutate, setStage, invite]);

  useEffect(() => {
    if (isError) {
      displayToast("Something went wrong. Retry again after sometime", {
        type: "error",
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setStage?.(4);
    }
  }, [isSuccess, setStage]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === stackRef.current) {
          setStackHeight(entry.contentRect.height);
        }
      }
    });

    if (stackRef.current) {
      resizeObserver.observe(stackRef.current);
    }
    setTimeout(() => {
      if (stackRef.current) {
        setStackHeight(stackRef.current.clientHeight);
      }
    }, 100);
    const cleanUpNode = stackRef.current;
    return () => {
      if (cleanUpNode) {
        resizeObserver.unobserve(cleanUpNode);
      }
    };
  }, []);

  return (
    <Container
      bgImage={hotspot?.banner_image_url}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      borderRadius="0.75rem"
      borderBottom="1px solid"
      display="flex"
      justifyContent="center"
      w="auto"
      h="35rem"
      position="relative"
      pt="1rem"
      px="0rem"
      mx="1rem"
      onClick={() => setStage?.(1)}
    >
      <VStack alignItems="center" w="100%">
        <HotspotCardHeader
          isInvite={invite.validity && !showInterestedButton}
        />
        {blurDivs.map((_, index) => (
          <Container
            key={index}
            position="absolute"
            bottom="0"
            width="100%"
            height={`${stackHeight - index * heightDelta}px`}
            backgroundColor="rgba(16,16,16,0.1)"
            backdropFilter="auto"
            borderRadius="0.75rem"
            backdropBlur={`${1}px`}
            zIndex={19 - index}
          />
        ))}
        <VStack
          ref={stackRef}
          width="100%"
          h="auto"
          px="1rem"
          pb="1rem"
          alignItems="center"
          position="absolute"
          bottom="0rem"
          pt="3rem"
          zIndex={20}
        >
          <HStack spacing={2}>
            <Image
              src={hotspot?.host.user.profile_image_url}
              boxSize="24px"
              borderRadius="full"
            />
            <Flex alignItems="center">
              <Text color="white" fontSize="sm" fontWeight="700">
                {hotspot?.host?.user.name}
              </Text>
              <Image src={verified} boxSize="12px" ml="2px" />
              <Text fontSize="sm" fontWeight="400" color="#FFFFFF7A" ml={1}>
                is hosting
              </Text>
            </Flex>
          </HStack>
          <Text
            fontFamily="titan"
            color="white"
            fontSize="2rem"
            textAlign="center"
            wordBreak="break-word"
          >
            {hotspot?.name}
          </Text>
          <Text
            color="white"
            textAlign="center"
            fontWeight="400"
            py="0.5rem"
            fontSize="md"
          >
            A {hotspot?.experience.name} Hotspot
          </Text>
          {invite.validity && !showInterestedButton ? (
            <AcceptInviteButton
              onClick={handleAcceptInvite}
              isLoading={isPending}
            />
          ) : (
            <InterestedButton onClick={buttonClick} isLoading={isPending} />
          )}
        </VStack>
      </VStack>
    </Container>
  );
};

export default HotspotCard;
