import { UserGender, WaitlistStatus } from "@interfaces/user";
import { create } from "zustand";

interface IAddress {
  id: number;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  latitude: number;
  longitude: number;
}

interface IUser {
  id: number;
  name: string;
  gender: UserGender;
  social_profile_id: string;
  status: WaitlistStatus;
  profile_image_url: string;
}

interface IHost {
  id: number;
  status: WaitlistStatus;
  user: IUser;
}

interface IExperience {
  id: number;
  name: string;
  tagline: string;
  description: string;
  image_url: string;
  icon_url: string;
}
interface IHotspot {
  id: number;
  name: string;
  description: string;
  address: IAddress;
  host: IHost;
  experience: IExperience;
  start_datetime: string;
  end_datetime: string;
  banner_image_url: string;
  capacity: number;
}

interface IHotspotStore {
  hotspot: IHotspot | undefined;
  validity: boolean;
  setHotspot: (hotspot: IHotspot) => void;
  setValidity: (valid: boolean) => void;
}

const useHotspotStore = create<IHotspotStore>((set) => ({
  hotspot: undefined,
  validity: false,
  setHotspot: (hotspot: IHotspot) => set({ hotspot }),
  setValidity: (validity: boolean) => set({ validity }),
}));

export default useHotspotStore;
