export enum UserGender {
  "Male" = 1,
  "Female" = 2,
  "Non Binary" = 3,
}

export enum WaitlistStatus {
  DRAFT = 1,
  WAITLIST = 2,
  ON_HOLD = 3,
  ACCEPTED = 4,
  REJECTED = 5,
}

type FreeFlowingJSON = {
  [key: string]: string | FreeFlowingJSON;
};

export interface IUpdateUser {
  name: string;
  date_of_birth: string;
  gender: UserGender | null;
  profile_image_url: string;
  extras_json: FreeFlowingJSON;
}
