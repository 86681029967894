import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";

import axiosInstance from "./axiosInstance";

const getReasons = async () => {
  try {
    const response = await axiosInstance.get("/v1/invites/rejection-reasons");
    return response.data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    return e;
  }
}

const rejectInvite = async (id: string, reasons: string[]) => {
  try {
    const response = await axiosInstance.post(`/v1/invites/${id}`, { reasons, action: "reject" });
    return response.data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    return e;
  }
}

const getInviteFromCode = async (code: string) => {
  try {
    const response = await axiosInstance.get(`/v1/invites/code/${code}`);
    return response.data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    return e;
  }
}

const acceptInvite = async (id: string) => {
  try {
    const response = await axiosInstance.post(`/v1/invites/${id}/action`, { action: "accept" });
    return response.data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    return e;
  }
}

export const useGetReasons = (isOpen: boolean) => {
  const query = useQuery({
    queryKey: ["rejectionReasons"],
    queryFn: getReasons,
    enabled: isOpen,
  });
  return query;
}

export const useRejectInvite = () => {
  const mutation = useMutation({
    mutationKey: ["rejectInvite"],
    mutationFn: ({ id, reasons }: { id: string; reasons: string[] }) => rejectInvite(id, reasons),
  });
  return mutation;
}

export const useGetInviteFromCode = (code: string) => {
  const query = useQuery({
    queryKey: ["invite", code],
    queryFn: () => getInviteFromCode(code),
    retry: false,
  });
  return query;
}

export const useAcceptInvite = () => {
  const mutation = useMutation({
    mutationKey: ["acceptInvite"],
    mutationFn: (id: string) => acceptInvite(id),
  });
  return mutation;
}