import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Text,
  VStack,
  FormErrorMessage,
} from "@chakra-ui/react";
import { UserGender } from "@interfaces/user";
import useUserStore from "@stores/user";
import React, { useState } from "react";


import FormProfilePictureUpload from "./FormProfilePictureUpload";

import { useGetAllExperiences } from "@/api/experience";
import displayToast from "@/utils/toasts";

const genderOptions = [
  { value: UserGender.Male, label: "Male" },
  { value: UserGender.Female, label: "Female" },
  { value: UserGender["Non Binary"], label: "Non-binary" },
];

const isValidDate = (dateString: string) => {
  const [year, month, day] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day);

  return (
    date instanceof Date &&
    !isNaN(date.getTime()) &&
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day &&
    calculateAge(dateString ?? "") >= 13 &&
    calculateAge(dateString ?? "") <= 100
  );
};

const calculateAge = (dateOfBirth: string): number => {
  if (!dateOfBirth) return 0;
  const birthDate = new Date(dateOfBirth);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

interface FormProps {
  setFormValid: (valid: boolean) => void;
}

const Form: React.FC<FormProps> = ({ setFormValid }) => {
  const {
    user,
    setUser,
    photo,
    instaHandle,
    setInstaHandle,
    setInterestedExperiences,
  } = useUserStore((state) => ({
    user: state.user,
    setUser: state.setUser,
    photo: state.photo,
    instaHandle: state.instaHandle,
    setInstaHandle: state.setInstaHandle,
    setInterestedExperiences: state.setInterestedExperiences,
  }));
  const [dateError, setDateError] = useState<boolean>(false);

  const { data: experiences } = useGetAllExperiences(); // Fetch all experiences

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    setUser({ ...user, date_of_birth: date });
    if (isValidDate(date)) {
      setDateError(false);
    } else {
      setDateError(true);
    }
  };

  const selected = [...(user?.interested_experiences || [])];
  // function to handle interested experience selection
  const handleExperienceSelect = (experienceId: number) => {
    if (selected.includes(experienceId)) {
      // Remove the experience
      const updatedExperiences = selected.filter((id) => id !== experienceId);
      setInterestedExperiences(updatedExperiences);
    } else if(selected.length < 5) {
      // Add the experience
      setInterestedExperiences([...selected, experienceId]);
    }
    else {
      displayToast("You can only select upto 5 experiences.", {
        type: "warning",
      });
    }
  };

  const isFormValid =
    !!user?.name &&
    !!user?.date_of_birth &&
    !!user?.gender &&
    !!(photo || user.profile_image_url) &&
    !!instaHandle &&
    (user?.interested_experiences || []).length > 0 &&
    isValidDate(user?.date_of_birth);

  React.useEffect(() => {
    setFormValid(isFormValid);
  }, [isFormValid, setFormValid]);

  return (
    <Box p="1rem" color="white" pb={isFormValid ? "0.5rem" : "2rem"}>
      <Text fontSize="2xl" mt="1rem" fontWeight="700">
        Let's get started
      </Text>
      <VStack spacing={4} align="stretch" mt="1rem">
        <FormControl>
          <FormQuestion questionNumber={1} question="Add a profile photo" />
          <FormProfilePictureUpload />
        </FormControl>

        <FormControl>
          <FormQuestion questionNumber={2} question="What do we call you?" />
          <Input
            placeholder="Your name"
            value={user?.name ?? ""}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
            _placeholder={{ color: "placeholder" }}
            border="grey"
            _focus={{ border: "blue" }}
            fontSize="md"
            p={6}
            bg="b3"
          />
        </FormControl>
        <FormControl isInvalid={!!dateError}>
          <FormQuestion questionNumber={3} question="What's your birth date?" />
          <Input
            type="date"
            value={user?.date_of_birth ?? ""}
            onChange={handleDateChange}
            color={user?.date_of_birth ? "white" : "placeholder"}
            border="grey"
            _focus={{ border: "blue" }}
            fontSize="md"
            p={6}
            bg="b3"
          />
          {dateError && (
            <FormErrorMessage color="red" fontSize="sm">
              Please enter a valid date
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl>
          <FormQuestion
            questionNumber={4}
            question="Who do you identify yourself as?"
          />
          <Flex gap={2} justify="space-around">
            {genderOptions.map((g) => (
              <Button
                key={g.value}
                onClick={() => setUser({ ...user, gender: g.value })}
                variant={user?.gender === g.value ? "solid" : "outline"}
                border={user?.gender === g.value ? "blue" : "grey"}
                background={"b3"}
                width="auto"
                color={user?.gender === g.value ? "purple" : "placeholder"}
                px="2rem"
                py="1.5rem"
                isActive={user?.gender === g.value}
                _active={{ background: "#9196FF2D" }}
                _hover={{ background: "#9196FF2D" }}
              >
                {g.label}
              </Button>
            ))}
          </Flex>
        </FormControl>
        <FormControl>
          <FormQuestion
            questionNumber={5}
            question="What types of hotspots interests you?"
          />
          <Flex gap={2} flexWrap="wrap">
            {experiences?.map((experience: any) => (
              <Button
                key={experience.id}
                background={"b3"}
                width="auto"
                px="1rem"
                py="1.5rem"
                variant={
                  selected?.includes(experience.id) ? "solid" : "outline"
                }
                border={selected?.includes(experience.id) ? "blue" : "grey"}
                _active={{ background: "#9196FF2D" }}
                _hover={{ background: "#9196FF2D" }}
                color={
                  selected?.includes(experience.id) ? "purple" : "placeholder"
                }
                isActive={selected?.includes(experience.id)}
                onClick={() => handleExperienceSelect(experience.id)}
                flexGrow={1}
              >
                {experience.name}
              </Button>
            ))}
          </Flex>
        </FormControl>
        <FormControl>
          <FormQuestion
            questionNumber={6}
            question="What's your instagram handle?"
          />
          <Input
            placeholder="ranook.xd"
            value={instaHandle}
            onChange={(e) => setInstaHandle(e.target.value)}
            _placeholder={{ color: "placeholder" }}
            border="grey"
            _focus={{ border: "blue" }}
            fontSize="md"
            p={6}
            bg="b3"
          />
        </FormControl>
      </VStack>
    </Box>
  );
};

interface FormQuestionProps {
  questionNumber: number;
  question: string;
}

const FormQuestion: React.FC<FormQuestionProps> = ({
  questionNumber,
  question,
}) => {
  return (
    <Flex mb={2} alignItems="center">
      <Text fontSize="xs" color="text4">
        0{questionNumber}
      </Text>
      <Text fontSize="sm" color="text3" ml={2}>
        {question}
      </Text>
    </Flex>
  );
};

export default Form;
