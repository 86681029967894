import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";

import axiosInstance from "./axiosInstance";

// API to update user interested experiences
const updateUserExperiences = async (experiences: number[]) => {
  try {
    const response = await axiosInstance.put("/v1/users/interested-experiences", { experiences });
    return response.data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    return e;
  }
};  

// API to get the list of all experiences
const getAllExperiences = async () => {
  try {
    const response = await axiosInstance.get("/v1/experiences?active=true");
    return response.data.data.experiences;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      throw e;
    }
    return e;
  }
};

// Custom hook for updating user interested experiences
export const useUpdateUserExperiences = () => {
  const mutation = useMutation({
    mutationKey: ["updateUserExperiences"],
    mutationFn: (experiences: number[]) => updateUserExperiences(experiences),
  });
  return mutation;
};

// Custom hook for getting all experiences
export const useGetAllExperiences = () => {
  const query = useQuery({
    queryKey: ["experiences"],
    queryFn: getAllExperiences,
  });
  return query;
};