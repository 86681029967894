export enum AssetPurpose {
  PROFILE_PICTURE = 1,
  GALLERY = 2,
  INTRO_VIDEO = 3,
}

export enum AssetType {
  IMAGE = 1,
  VIDEO = 2,
  AUDIO = 3,
  DOCUMENT = 4,
}

interface IAsset {
  purpose: AssetPurpose;
  type: AssetType;
}

export default IAsset;
