import bgImage from "@assets/bg_form.png";
import { Box, VStack, Fade, Image } from "@chakra-ui/react";
import Form from "@components/Form";
import HotspotDetails from "@components/HotspotDetails";
import useSubmit from "@hooks/useSubmit";
import { WaitlistStatus } from "@interfaces/user";
import useUserStore from "@stores/user";
import React, { useEffect } from "react";

import PrimaryCTA from "@/buttons/PrimaryCTA";

interface OnboardingFormProps {
  setStage: (stage: number) => void;
}

const OnboardingForm: React.FC<OnboardingFormProps> = ({setStage}) => {
  const [isFormValid, setIsFormValid] = React.useState(false);
  const { handleSubmit, success, errorOccurred } =
    useSubmit();
  const setStatus = useUserStore((state) => state.setStatus);

  useEffect(() => {
    if (success) {
      setStatus(WaitlistStatus.WAITLIST);
      setStage(4);
    }
  }, [success, setStage, setStatus]);

  return (
    <Box
      w="100%"
      h="100%"
      backgroundColor="black"
      position="relative"
      overflowY="auto"
      sx={{
        scrollbarWidth: "none",
      }}
    >
      <Image src={bgImage} position="absolute" top={0} left={0} opacity={0.6}/>
      <VStack mx="1rem" pt="2rem">
        <HotspotDetails isWaitlistPage />
      </VStack>
      <Form setFormValid={setIsFormValid} />
      <Box position="relative" bottom="0" width="100%" pb="0.5rem">
        <Fade in={isFormValid}>
          <Box bg="black" p="1rem"  display="flex" justifyContent="center">
            <PrimaryCTA
              text="Submit"
              onClick={handleSubmit}
              display={isFormValid}
              isLoading={errorOccurred || success}
              isDisabled={!isFormValid}
            />
          </Box>
        </Fade>
      </Box>
    </Box>
  );
};

export default OnboardingForm;
