import { Divider, Flex, HStack, Text, Container, Box } from "@chakra-ui/react";
import Point from "@components/Point";
import useHotspotStore from "@stores/hotspot";
import useInviteStore from "@stores/invite";
import dayjs from "dayjs";
import { CalendarCheck, MapPin, Users } from "lucide-react";
import React, { useState, useEffect } from "react";

import Spin from "./Spinner";

function formatDateString(dateString: string): string {
  const date = dayjs(dateString);
  const day = date.format("D");
  const month = date.format("MMM");
  const year = date.format("YY");
  return `${day} ${month}'${year}`;
}

const formatTime = (seconds: number) => {
  const timeDuration = dayjs.duration(seconds, "seconds");
  const hours = timeDuration.hours() + timeDuration.days() * 24;
  const minutes = timeDuration.minutes();
  const secs = timeDuration.seconds();
  return `${hours}h ${minutes}m ${secs}s`;
};
interface HotspotCardHeaderProps {
  isInvite: boolean;
}

const HotspotCardHeader: React.FC<HotspotCardHeaderProps> = ({ isInvite }) => {
  const hotspot = useHotspotStore((state) => state.hotspot);
  const validity = useHotspotStore((state) => state.validity);
  const invite = useInviteStore((state) => state.invite);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [timeLeft]);

  useEffect(() => {
    const expiryDateTime = new Date(invite.expiry_datetime);
    const diff = expiryDateTime.getTime() - new Date().getTime()
    setTimeLeft(diff / 1000);
  }, [invite]);

  if (timeLeft == 0)
    return (
      <Box w="100%" background="transparent">
        <Spin isBlack={false} />
      </Box>
    );

  return (
    <Container
      borderRadius="0.5rem"
      py={3}
      backdropFilter="auto"
      width="fit-content"
      display="inline-flex"
      position="absolute"
      top="1rem"
      zIndex={2}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        backgroundColor="#10101080"
        backdropFilter="blur(16px)"
        borderRadius="0.5rem"
        zIndex={-1}
      />
      {!isInvite ? (
        <HStack
          spacing={2}
          alignItems="center"
          justifyContent="space-around"
          w="100%"
        >
          <Point isRed={!validity} />
          <HStack spacing={2} ml={1}>
            <Flex alignItems="center">
              <Users size={12} color="white" />
              <Text fontWeight="400" color="white" ml={1} fontSize="xs">
                {hotspot?.capacity}
              </Text>
            </Flex>
            <Divider orientation="vertical" height="12px" borderColor="white" />
            <Flex alignItems="center">
              <CalendarCheck size={12} color="white" />
              <Text fontWeight="400" color="white" ml={1} fontSize="xs">
                {formatDateString(hotspot?.start_datetime ?? "")}
              </Text>
            </Flex>
            <Divider orientation="vertical" height="12px" borderColor="white" />
            <Flex alignItems="center">
              <MapPin size={12} color="white" />
              <Text fontWeight="400" color="white" ml={1} fontSize="xs">
                {hotspot?.address.city}
              </Text>
            </Flex>
          </HStack>
        </HStack>
      ) : (
        <HStack spacing={2} alignItems="center">
          <Point />
          <HStack spacing={2} ml={1} justifyContent="space-between">
            <HStack>
              <Text fontSize="xs" color="white">
                Accept invite in
              </Text>
              <Text fontSize="xs" fontWeight="700" color="white">
                {formatTime(timeLeft)}
              </Text>
            </HStack>
            <Divider orientation="vertical" height="12px" borderColor="white" />
            <Flex alignItems="center">
              <Users size={12} color="white" />
              <Text color="white" ml={1} fontSize="xs">
                {hotspot?.capacity}
              </Text>
            </Flex>
          </HStack>
        </HStack>
      )}
    </Container>
  );
};

export default HotspotCardHeader;
