import { useGetHotspotFromCode } from "@api/hotspot";
import useHotspotStore from "@stores/hotspot";
import { useEffect } from "react";

import displayToast from "@/utils/toasts";

const useFetchHotspot = (code: string) => {
  const { data, isLoading, isError, error } = useGetHotspotFromCode(code);
  const setHotspot = useHotspotStore((state) => state.setHotspot);
  const setValidity = useHotspotStore((state) => state.setValidity);
  const toastMessage = "Hotspot has expired" 


  useEffect(() => {
    if (data) {
      setHotspot(data.hotspot);
      setValidity(data.validity);
      if (!data.validity) {
        displayToast(toastMessage, { type: "error" });
      }
    }
  }, [data, setHotspot, setValidity, toastMessage]);

  return { isLoading, isError, error };
};

export default useFetchHotspot;
